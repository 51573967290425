import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 64px;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
`

export const Description = styled.div`
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 24px;
`

export const Link = styled.a`
  color: #c74513;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
