import React, { Component } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { graphql } from 'gatsby'

import Navbar from 'components/shared/Navbar'
import Footer from 'components/shared/Footer'
import Layout from 'components/shared/Layout'
import PageTitle from 'components/shared/PageTitle'

import Article from 'components/News/Article'

class News extends Component {
  render() {
    const {
      data: {
        allFile: { edges },
      },
    } = this.props

    const articles = [
      {
        title:
          'CVS Subsidiary, RxAmerica, Reached $5 Million Settlement with US for Allegedly Submitting False Pricing Relating to the Company’s Medicare Part D Plan',
        description:
          'In one of the first False Claims Act settlements involving Medicare’s Prescription Drug Program, known as Part D, RxAmerica LLC. Has entered into a civil settlement agreement with The United States in which it has agreed to pay the government $5.25 million.',
        link: '1. www.justice.gov CVS Subsidiary.pdf',
      },
      {
        title:
          'Manhattan U.S. Attorney Settles Civil Fraud Lawsuit Against SEEDCO for $1.725 Million',
        description:
          'The United States Attorney for the Southern District of New York settles fraud lawsuit against SEEDCO for $1.725 million. The defendant admits falsification of job placement records and agrees to implement reforms.',
        link:
          '2.Manhattan U.S. Attorney Settles Civil Fraud Lawsuit Against SEEDCO For $1.725 Million.pdf',
      },
      {
        title:
          'An Inconvenient Truth. How One CPA Became a High- Profile Whistleblower.',
        description:
          'Interview with our relator- Brian Aryai represented by Robert W. Sadowski. He urged young CPAs not to be afraid of speaking up.',
        link: '3 next-gen-nysscpa-ycpa.pdf',
      },
      {
        title:
          'Whistleblower and United States Attorney Achieve Settlement of Construction  Fraud',
        description:
          'Whistleblower Brian Aryai represented by Robert W. Sadowski and the United States Attorney for the Southern District of New York settled a False Claim Act qui tam case against Lend Lease (US) Construction LMB Inc. (formerly known as Bovis Lend Lease LMB Inc.).',
        link: '4 Aryai press release.pdf',
      },
      {
        title: 'Caregiver for Disabled People Sues New York state',
        description:
          'A New York State – employed caregiver for people with developmental disabilities sued the state accusing it of the retaliating against him for whistle- blowing. Our client- Jeffrey Monsour has brought to light a number of questionable practices by the state, as varied as routinely falsifying fire drills and turning a blind eye toward abuse of those in the state’s system of care for people with developmental disabilities.',
        link: '5.NY Times Monsour suit article.pdf',
      },
      {
        title:
          'Walk Don’t Run. Experts Advice Prudence and Caution for Potential Whistleblowers',
        description:
          'Robert Sadowski is quoted in an article advising potential whistleblowers.',
        link: '6.CFA Magazine quoting RWS.pdf',
      },
      {
        title:
          "Robert Sadowski is quoted in an article covering Sadowski Katz's recent jury trial victory",
        description:
          'State wants judge to overturn free-speech verdict OPWDD employee won federal suit defending right to speak up about problems',
        link:
          'https://www.timesunion.com/news/article/State-wants-judge-to-overturn-free-speech-verdict-12491446.php',
        external: true,
      },
      {
        title:
          "Robert Sadowski is quoted in an article covering Sadowski Katz's recent jury trial victory against New York State in an important whistleblower retaliation case",
        description:
          'To Jeff Monsour, the federal court jury verdict in his favor late last month wasn’t as significant for him as it was for those who may want to come forward to report misconduct by state agencies...',
        link:
          'https://poststar.com/news/local/lake-luzerne-resident-wins-retaliation-lawsuit-against-state/article_42b973e1-e06f-56d3-ae49-ce2988b5140f.html',
        external: true,
      },
      {
        title:
          'Sadowski Katz whistleblower client fully exonerated from criminal charges while his retaliation action against Hayner Hoyt moves forward',
        description:
          'John Rubar, a former vice president of Hayner Hoyt construction company, is suing the company, alleging it fired him in 2014 because he refused to go along with its scheme to defraud the...',
        link:
          'https://www.syracuse.com/crime/index.ssf/2016/06/charge_dismissed_against_ex-hayner_hoyt_exec_who_blew_whistle_on_company.html',
        external: true,
      },
    ]

    return (
      <Layout>
        <Navbar />
        <PageTitle title="News" subtitle="Be up to date." />

        <Container>
          <Row>
            <Col md={8} mdOffset={2}>
              {articles.reverse().map(article => (
                <Article
                  key={article.title}
                  file={
                    article.external
                      ? article.link
                      : edges.find(
                          edge => edge.node.relativePath === article.link
                        ).node.publicURL
                  }
                  {...article}
                />
              ))}
            </Col>
          </Row>
        </Container>

        <Footer />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
      edges {
        node {
          relativePath
          publicURL
        }
      }
    }
  }
`

export default News
